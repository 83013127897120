/**
 * @name: 仓储管理-入库记录接口文件
 * @author:
 * @date: 2023-08-04 17:46
 * @description： 仓储管理-入库记录接口文件
 * @update: 2023-08-04 17:46
 */
import {get, postJ} from "@/request"
import {IPageRes} from "@/apis/page";
import {ICheckinQuery} from "@/apis/storage/checkin/types";

/**
 * 分页查询
 * @returns
 */
export const checkinQueryApi = (param:ICheckinQuery) => get("/admin/checkin/query",param)

/**
 * 导出入库记录数据
 * @param params 查询参数
 * @returns
 */
export const exportCheckinExcelApi = (params: ICheckinQuery) => postJ("/admin/checkin/exportCheckinExcel", params,"blob")
