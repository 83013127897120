
/**
 * @name: 仓储管理-入库记录
 * @author:
 * @date: 2023-08-04 17:53
 * @description： 仓储管理-入库记录
 * @update: 2023-08-04 17:53
 */
import {Vue, Component} from "vue-property-decorator"
import type {ICrudOption} from "@/types/m-ui-crud"
import {exportDistributeRecordApi, getDistributeRecordApi} from "@/apis/storage/gateDist";
import {IDistributeRecord} from "@/apis/storage/gateDist/types";
import {deepCopy, exportFile} from "@/utils/common";
import {checkinQueryApi, exportCheckinExcelApi} from "@/apis/storage/checkin";
import {ICheckinQuery} from "@/apis/storage/checkin/types";

@Component({})
export default class storageCheckin extends Vue {
  // 表格加载状态
  tableLoading: boolean = true;
  // 表格数据
  tableData: any = []
  // 表格总数
  tableTotal = 0
  // 查询参数
  queryParam: ICheckinQuery = {
    page: 1,
    limit: 10
  }
  // 表单参数
  modelForm: any = {}
  // crud配置信息
  crudOption: ICrudOption = {
    menu: false,
    column: [
      {
        label: "入库单ID",
        prop: "id",
        align: 'center',
      },
      {
        label: "商品名称",
        prop: "productName",
        align: 'center',
        search: true
      },
      {
        label: "入库档口",
        prop: "gateIdList",
        align: "center",
        search: true,
        searchMultiple: true,
        type: 'select',
        dicUrl: "/api/admin/gate/getGateList",
        dicFormatter: (res: any) => ({list: res.data, label: 'gateName', value: 'id'}),
        hide: true
      },
      {
        label: "入库数量",
        prop: "checkQtyStr",
        align: 'center',
      },
      {
        label: "批次号",
        prop: "batchNo",
        align: 'center',
      },
      {
        label: "入库档口",
        prop: "gateName",
        align: 'center',
      },
      {
        label: "入库类型",
        prop: "types",
        align: 'center',
        dicData: [
          {
            label: '采购入库',
            value: 1
          },
          {
            label: '退货入库',
            value: 2
          }
        ],
      },
      {
        label: "入库类型",
        prop: "typesList",
        align: 'center',
        type: 'select',
        searchMultiple: true,
        dicData: [
          {
            label: '采购入库',
            value: 1
          },
          {
            label: '退货入库',
            value: 2
          }
        ],
        search: true,
        hide: true
      },
      {
        label: "经办人",
        prop: "handlerName",
        align: 'center',
      },
      {
        label: "入库时间",
        prop: "checkTime",
        align: "center",
        type: "daterange",
        width: 180,
        //@ts-ignore
        default: '--',
        // @ts-ignore
        defaultTime: ["06:00:00", "05:59:59"],
        search: true,
      },
    ]
  }
  // 入库数量
  checkinSum: string = '0.00'

  /**
   * 导出excel
   */
  exportExcel() {
    const form: ICheckinQuery = deepCopy(this.queryParam);
    if (this.queryParam.checkTime && this.queryParam.checkTime.length) {
      form.startTime = this.queryParam.checkTime[0]
      form.endTime = this.queryParam.checkTime[1]
    } else {
      form.startTime = ""
      form.endTime = ""
    }
    delete form.checkTime
    form.typesList = form.typesList || []
    form.gateIdList = form.gateIdList || []
    exportCheckinExcelApi(form).then(e => {
      exportFile(e, '入库记录.xlsx')
    })
  }

  getList() {
    const form: ICheckinQuery = deepCopy(this.queryParam);
    if (this.queryParam.checkTime && this.queryParam.checkTime.length) {
      form.startTime = this.queryParam.checkTime[0]
      form.endTime = this.queryParam.checkTime[1]
    } else {
      form.startTime = ""
      form.endTime = ""
    }
    delete form.checkTime
    form.typesList = form.typesList?.toString()
    form.gateIdList = form.gateIdList?.toString()
    checkinQueryApi(form).then(e => {
      //@ts-ignore
      this.tableData = e.checkinVoCommonPage.list;
      this.tableTotal = e.checkinVoCommonPage.total
      this.checkinSum = e.checkinSum
    })
  }

  created() {
    this.getList()
  }
}
